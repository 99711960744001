<template>
  <div :style="cssProps" class="page-wrap">
    <div class="session-form-hold">
      <v-card width="400px" max-width="100%">
        <v-card-text class="text-center">
          <img :src="require('@/images/logo.png')" alt="" />
          <router-view></router-view>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script lang="js">

import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
})

export default {
  name: 'Authorisation',
  computed: {
    cssProps() {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.page-wrap {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
